import React from 'react'
import '../../Styles/Footer.css'
import Logo from '../../Assets/Images/finduku-logo.svg'


function Footer() {
    return(
        <>
            <div className="footer">
                <div className="footer-container">
                    <ul className="socials">
                        <li className="socials-item">
                            <a href="https://www.facebook.com/finduku" className="socials-link" target="_blank" rel="noreferrer">
                                <i className="fa-brands fa-facebook-f"></i>
                            </a>
                        </li>
                        <li className="socials-item">
                            <a href="https://www.linkedin.com/company/finduku" className="socials-link" target="_blank" rel="noreferrer">
                                <i className="fa-brands fa-linkedin-in"></i>
                            </a>
                        </li>
                        <li className="socials-item">
                            <a href="https://www.twitter.com/finduku" className="socials-link" target="_blank" rel="noreferrer">
                                <i className="fa-brands fa-twitter"></i>
                            </a>
                        </li>
                        <li className="socials-item">
                            <a href="https://www.instagram.com/finduku" className="socials-link" target="_blank" rel="noreferrer">
                                <i className="fa-brands fa-instagram"></i>
                            </a>
                        </li>
                        <li className="socials-item">
                            <a href="https://www.tiktok.com/@finduku" className="socials-link" target="_blank" rel="noreferrer">
                                <i className="fa-brands fa-tiktok"></i>
                            </a>
                        </li>
                    </ul>
                    <div className="copyright">
                        <a href="/" className="image-container">
                            <img src={Logo} alt="Finduku Logo" />
                        </a>
                        <div className="copyright-notice">
                            <p>Copyright &#64; 2023 Finduku Inc. All Rights Reserved</p>
                        </div>
                    </div>
                    {/*
                        <ul className="legal">
                            <li className="legal-item">
                                <NavLink to="/" className="legal-link">
                                    <p>Privacy Policy</p>
                                </NavLink>
                            </li>
                            <li className="legal-item">
                                <NavLink to="/" className="legal-link">
                                    <p>Cookie Preferences</p>
                                </NavLink>
                            </li>
                            <li className="legal-item">
                                <NavLink to="/" className="legal-link">
                                    <p>Terms of Use</p>
                                </NavLink>
                            </li>
                        </ul>
                    */}
                </div>
            </div>
        </>
    );
}

export default Footer