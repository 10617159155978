import React, { useState } from 'react'
import { Routes, Route } from 'react-router-dom'
import './App.css'

import Layout from './HOCS/Layout';
import Home from './Pages/Home/Home';
import ScrollContext from './Pages/Home/Homepages/ScrollContext'


function App() {
  const [scrollContext, setScrollContext] = useState({});

  return (
    <div className="App">
        <ScrollContext.Provider value={scrollContext}>
          <Layout>
            <Routes>
              <Route path="/" element={<Home setScrollContext={setScrollContext} />} />
            </Routes>
          </Layout>
        </ScrollContext.Provider>
    </div>
  );
}

export default App;
