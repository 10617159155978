import React from 'react';
import { Outlet } from 'react-router-dom';
import Footer from '../Components/Footer/Footer';
import Header from '../Components/Header/Header';

const Layout = ({ children, scrollToWhitelist }) => {
  return (
    <>
        <Header scrollToWhitelist={scrollToWhitelist} />
        <Outlet />
        {children}
        <Footer />
    </>
    
  )
}

export default Layout