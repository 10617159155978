import React, { useState } from 'react'
import '../../../Styles/Whitelist.css'
import axios from 'axios'

const Whitelist = React.forwardRef((props, ref) => {

  const [confirmationMessage, setConfirmationMessage] = useState('');
  const [formVisible, setFormVisible] = useState(true);
  const [disableButton, setDisableButton] = useState(true);

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const name = e.target.name.value;
    const email = e.target.email.value;

    if (!name || !email) {
      return;
    }
  
    try {
      const response = await axios.post(
        'https://api.finduku.com/apps/v1/',
        {
          name,
          email,
        }
      );
  
      if (response.data.error) {
        console.error('Error creating item:', response.data.error);
        setConfirmationMessage(''); 
      } else {
        console.log('Item created successfully');
        setConfirmationMessage("Thanks for your interest.\nWe'll reach out soon!");
        setFormVisible(false);
      }
    } catch (error) {
      console.error('Error making API request:', error);
      setConfirmationMessage('');
    }
  };

  const handleInputChange = (e) => {
    
    const nameInput = e.target.closest('form').querySelector('#name');
    const emailInput = e.target.closest('form').querySelector('#email');
    
    if (nameInput.value && emailInput.value) {
      setDisableButton(false);
    } else {
      setDisableButton(true);
    }

  };


  return (
    <>
      <div className="whitelist" ref={ref} id="waitlist">
        <div className="page-container">
          <div className="title">
            <p>Discover and be part of the next generation of payments</p>
            <p>Join our waitlist for early access</p>
          </div>
          <div className="form" >
            {formVisible ? (
              <form onSubmit={handleSubmit} >
                  <input
                    type="text"
                    id="name"
                    name="name"
                    placeholder="Full Name"
                    autoComplete="off"
                    onChange={handleInputChange}
                  />
                  <input
                    type="email"
                    id="email"
                    name="email"
                    placeholder="Email Address"
                    autoComplete="off"
                    onChange={handleInputChange}
                  />
                  <button type="submit" disabled={disableButton}>Join Waitlist</button>
              </form>
            ) : (
              <div className="confirmation-message">
                {confirmationMessage}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
});

export default Whitelist;