import React from 'react'
import '../../../Styles/Problems.css'
import scan_qr from '../../../Assets/Images/scan-qr.svg'
import instant_payout from '../../../Assets/Images/instant-payout.svg'
import payment_auth from '../../../Assets/Images/payment-auth.svg'



function Problems() {
    return (
        <>
            <div className="problem-section">
                <div className="section-container">
                    <div className="background-container">
                        <div className="header">
                            <p>Accept bank payments in a new way</p>
                            <p>Receive bank payments without relying on traditional bank transfers</p>
                        </div>
                        <div className="sub-header">
                            
                        </div>
                        <div className="content">
                            <div className="statement">
                                <p className="title">
                                    Simplify payments by letting your customers just scan to pay
                                </p>
                                <div className="issues">
                                    <div className="issues-item">
                                        <div className="issue-icon">
                                            <i className="fa-sharp fa-solid fa-check" style={{color: "#1f4168"}}></i>
                                        </div>
                                        <p>
                                            Speed up each transaction by letting your customers 
                                            pay by simply scanning your store's QR code
                                        </p>
                                    </div>
                                    <div className="issues-item">
                                        <div className="issue-icon">
                                            <i className="fa-sharp fa-solid fa-check" style={{color: "#1f4168"}}></i>
                                        </div>
                                        <p>
                                            You may also send a payment link to your customers 
                                            via SMS, email, messenger, whatsapp, viber and more
                                        </p>
                                    </div>
                                    <div className="issues-item">
                                        <div className="issue-icon">
                                            <i className="fa-sharp fa-solid fa-check" style={{color: "#1f4168"}}></i>
                                        </div>
                                        <p>
                                            For online merchants, your customers can simply select the Pay by Bank option
                                            at the checkout page
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="illustration">
                                <img src={scan_qr} alt="Problem Illustration" />
                            </div>
                        </div>
                        <div className="mid-content">
                            <div className="illustration">
                                <img src={payment_auth} alt="Problem Illustration" />
                            </div>
                            <div className="statement">
                                <p className="title">
                                    Enhance customer experience with frictionless bank payments
                                </p>
                                <div className="issues">
                                    <div className="issues-item">
                                        <div className="issue-icon">
                                                <i className="fa-sharp fa-solid fa-check" style={{color: "#1f4168"}}></i>
                                        </div>
                                        <p>
                                            Empower your customers with seamless payments 
                                            by enabling them to use just their mobile banking apps
                                        </p>
                                    </div>
                                    <div className="issues-item">
                                        <div className="issue-icon">
                                                <i className="fa-sharp fa-solid fa-check" style={{color: "#1f4168"}}></i>
                                        </div>
                                        <p>
                                            Give your customers more payment options by allowing 
                                            them to pay using either their deposit or credit accounts
                                        </p>
                                    </div>
                                    <div className="issues-item">
                                        <div className="issue-icon">
                                                <i className="fa-sharp fa-solid fa-check" style={{color: "#1f4168"}}></i>
                                        </div>
                                        <p>
                                            Help your customers save money on transaction fees with Pay by Bank payment that doesn't charge fees
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="content">
                            <div className="statement">
                                <p className="title">
                                    Track your business growth with a centralized dashboard
                                </p>
                                <div className="issues">
                                    <div className="issues-item">
                                        <div className="issue-icon">
                                            <i className="fa-sharp fa-solid fa-check" style={{color: "#1f4168"}}></i>
                                        </div>
                                        <p>
                                            Save up to 80% on transaction fees with Pay by Bank, 
                                            reducing your payment processing costs significantly
                                        </p>
                                    </div>
                                    <div className="issues-item">
                                        <div className="issue-icon">
                                            <i className="fa-sharp fa-solid fa-check" style={{color: "#1f4168"}}></i>
                                        </div>
                                        <p>
                                            Gain valuable insights into your business by tracking  
                                            growth with real-time reconciliation of transactions
                                        </p>
                                    </div>
                                    <div className="issues-item">
                                        <div className="issue-icon">
                                            <i className="fa-sharp fa-solid fa-check" style={{color: "#1f4168"}}></i>
                                        </div>
                                        <p>
                                            Instant settlement by receiving funds from customers 
                                            almost immediately after the transaction is completed
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="illustration">
                                <img src={instant_payout} alt="Problem Illustration" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Problems