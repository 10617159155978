import React, { useState, useContext } from 'react'
import '../../Styles/Header.css'
import Logo from '../../Assets/Images/finduku-logo.svg'
import ScrollContext from '../../Pages/Home/Homepages/ScrollContext'


function Header() {

  const context = useContext(ScrollContext);

  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);

  const [header, setHeader] = useState(false);
  const scrollHeader = () =>{
    if(window.scrollY >= 20){
      setHeader(true)
    }else{
      setHeader(false)
    }
  }
  
  window.addEventListener("scroll", scrollHeader)

  return (
    <>
      <nav className={header ? 'navbar-scroll' : 'navbar'}>
        <div className="nav-container">
          <a href="/" className="nav-logo">
            <img src={Logo} alt="Finduku Logo"/>
          </a>

          <ul className={click ? "nav-menu active" : "nav-menu"}>
 
            {/*
              <li className="nav-item active">
                <NavLink to="/about-us" className="nav-links" onClick={handleClick} >
                  About Us
                </NavLink>
              </li>
            */}
            <li className="nav-item active">
              <a href="mailto:info@finduku.com" target="_blank"  rel="noreferrer" className="nav-links">
                Contact Us
              </a>
            </li>
          </ul>
            <button className="waitlist-btn" onClick={context.scrollToWhitelist}>
                Get Started
            </button>

          <div className="nav-icon" onClick={handleClick}>
            <i className={click ? "fa-regular fa-xmark-large" : "fas fa-bars"}></i>
          </div>
        </div>
      </nav>
    </>
  );
}

export default Header;
