import React, { useRef, useEffect }  from 'react'
import '../../Styles/Homepages.css'
import { Outlet } from 'react-router-dom';
import Hero from './Homepages/Hero';
import Problems from './Homepages/Problems';
import Customers from './Homepages/Customers';
import Whitelist from './Homepages/Whitelist';



function Home ({ setScrollContext }) {
  const whitelistRef = useRef(null);

  const focusNameInput = () => {
    const nameInput = whitelistRef.current.querySelector('input[name="name"]');
    if (nameInput) {
      setTimeout(() => {
        nameInput.focus();
      }, 500); // Adjust this value to match the scroll duration
    }
  };

  useEffect(() => {
    setScrollContext({
      scrollToWhitelist: () => {
        whitelistRef.current.scrollIntoView({ behavior: 'smooth' });
        focusNameInput();
      },
    });
  }, [setScrollContext]);

  return (
    <>
        <div className="homepage">
          <Hero />
          <Problems />
          <Customers />
          <Whitelist ref={whitelistRef} />
          <Outlet />
        </div>
    </>
  )
}

export default Home