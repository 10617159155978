import React, {useContext} from 'react'
import HeroImage from '../../../Assets/Images/myheroimage.svg'
import ScrollContext from './ScrollContext';


function Hero () {

  const context = useContext(ScrollContext);

  return (
    <>
        <div className="hero-section">
          <div className="hero-container">
            <div className="sub-section">
              <div className="cta-section">
                <div className="hero-title">
                  <p>Bank</p>
                  <p>Payments</p>
                  <p>made easier than ever</p>
                </div>
                <div className="hero-caption">
                  <p>
                    Experience the transformation of bank payments in the Philippines. 
                  </p>
                  <p>
                    With a single integration, enable your business to accept payments directly from your customers' 
                    bank accounts and credit lines.
                  </p>
                </div>
  
                <button className="join-waitlist-btn" onClick={context.scrollToWhitelist}>
                  <div className="join-waitlist-link">
                        Join Waitlist
                        <i className="fa fa-chevrons-down join-waitlist-icon"></i>
                  </div>
                </button>
              </div>
              <div className="hero-image-section">
                <img src={HeroImage} alt="Hero" />
              </div>
            </div>

          </div>
        </div>
    </>
  );
};

export default Hero